export const AllData = {
  application: [
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
  ],
  under_l1: [
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
  ],
  under_l2: [
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
  ],
  pending_C_A: [
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
  ],
  approved: [
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
  ],
  rejected: [
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
  ],
  under_l2_wr: [
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
    {
      name: "1",
      mobile: "#1232342",
      email: "Mr. Rohit Kumar",
      area: "9876543210",
      gstNo: "Baridih",
      isReturn: "Kashidih",
      expiration: "12/01/2023",
    },
  ],
};

export default AllData;
